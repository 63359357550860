.carousel_main{
    height: 20rem;
    filter: brightness(30%);
    /* filter: blur(0.5); */
}

.img{
    height: 100%;
    z-index: 0;
    width: 100%;
}


.divide{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    position: absolute;
    top: 30%;
    z-index: 99;
}

.lines{
    display: flex;
    gap: 0.5rem;
}

.line1{
    background-color: #ecc371;
    height: 2px;
    width: 5rem;
}

.line2{
    background-color: #ecc371;
    height: 2px;
    width: 5rem;
}

.circle{
    background-color: #ecc371;
    height: 2px;
    width: 2px;
    border-radius: 50%;
}