@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    scrollbar-width: none !important;
}




.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
  
  
  .swiper-pagination-bullet{
    background-color: #ecc371 !important;
  }
  


.noscroll{
    scrollbar-width: none !important;
}

.main{
    background-color: #212327;
    /* height: 10rem; */
 }
 
 
 .divide{
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     padding-bottom: 2rem;
 }
 
 .logo{
     /* style={{height:'3rem',margin:'2rem'}} */
     height: 5rem;
     padding: 1rem;
     /* margin: 2rem; */
 }
 
 .loader{
     height: 12rem;
 }
 
 
 .divide1{
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     /* padding-bottom: 2rem; */
     position: absolute;
     top: 30%;
     left: 42%;
     z-index: 99;
     background: none;
 }
 
 
 .lines{
     display: flex;
     gap: 0.5rem;
 }
 
 .line1{
     background-color: #ecc371;
     height: 2px;
     width: 5rem;
     border-radius: 20px;
 }
 
 .line2{
     background-color: #ecc371;
     height: 2px;
     width: 5rem;
     border-radius: 20px;
 }
 
 .circle{
     background-color: #ecc371;
     height: 2px;
     width: 2px;
     border-radius: 50%;
 }
 
 
 @media only screen and (max-width:1060px){
     .divide1{
         display: flex;
         justify-content: center;
         flex-direction: column;
         align-items: center;
         padding-bottom: 2rem;
         position: absolute;
         top: 25%;
         left: 35%;
         z-index: 99;
         background: none;
     }
 }
 
 
 @media only screen and (max-width:528px){
     .divide1{
         display: flex;
         justify-content: center;
         flex-direction: column;
         align-items: center;
         padding-bottom: 2rem;
         position: absolute;
         top: 25%;
         left: 20%;
         z-index: 99;
         background: none;
     }
 }