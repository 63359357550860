/* defaultbutton {
    --color: #f3a25f;
    font-family: inherit;
    display: inline-block;
    width: 6em;
    height: 2.6em;
    line-height: 2.5em;
    margin: 2px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color 0.5s;
    z-index: 1;
    font-size: 17px;
    border-radius: 8px;
    font-weight: 400;
    color: var(--color);
  }
  
  defaultbutton:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
  }
  
  defaultbutton:hover {
    color: #fff;
  }
  
  defaultbutton:before {
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }
  
  defaultbutton:hover:before {
    top: -30px;
    left: -30px;
  }
  
  defaultbutton:active:before {
    background: #3a0ca3;
    transition: background 0s;
  }
  


   */



   .defaultbutton {
    width: 6em;
    position: relative;
    height: 2.5em;
    border: 3px ridge #ea7414;
    outline: none;
    background-color: transparent;
    color: white;
    transition: 1s;
    border-radius: 0.3em;
    font-size: 16px;
    font-weight: bold;
  }
  
  .defaultbutton::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 3%;
    width: 95%;
    height: 40%;
    /* background-color: #212121; */
    transition: 0.5s;
    transform-origin: center;
  }
  
  .defaultbutton::before {
    content: "";
    transform-origin: center;
    position: absolute;
    top: 80%;
    left: 3%;
    width: 95%;
    height: 40%;
    /* background-color: #212121; */
    transition: 0.5s;
  }
  
  .defaultbutton:hover::before, defaultbutton:hover::after {
    transform: scale(0)
  }
  
 .defaultbutton:hover {
    box-shadow: inset 0px 0px 25px #ea9414;
  }